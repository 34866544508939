
import { getAuth } from 'firebase/auth';

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
} from 'mdbvue';

export default {
  name: 'login',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
  },
  mounted(): void {
    const auth = getAuth();
    const ui = new firebaseui.auth.AuthUI(auth);
    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
            adminEmail: 's@sm1.ca',
          },
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start('#firebaseui-auth-container', uiConfig);
  },
};
